export const getUsersImagePath = (photoUrl?: string) => {
  if (!photoUrl) return '/static/user/default.svg';
  return `https://worknity-users.s3.us-west-1.amazonaws.com/${photoUrl}`;
};

export const getPathwaysImagePath = (photoUrl: string) =>
  `https://worknity-pathways.s3.us-west-1.amazonaws.com/${photoUrl}`;

export const getCompanyPath = () => '/static/organization/default.svg';

export const getEducationalInstitutionPath = () => '/static/educationalInstitution/default.svg';

export const getStudentPath = () => '/static/student/default.svg';

export const getProfessionalPath = () => '/static/professional/default.svg';
